import React from "react";
import { Link } from "gatsby";

const Content = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Content Section */}
            <div className="col-xl-12 col-lg-7 col-md-8 col-sm-11">
              <div
                className="ml-lg-10 ml-xl-12 mt-lg-n20 pr-lg-10 pr-xl-10"
                data-aos="fade-left"
                data-aos-duration={800}
                data-aos-delay={300}
                data-aos-once="true"
              >
                <h6 className="kit-label mb-9">Agente Digitalizador Oficial</h6>
                <h3 className="font-size-10 mb-8 mb-lg-8 font-weight-medium pr-sm-20 pr-md-16 pr-lg-0">
                  ¡Timeview gratis con el Bono Digital!
                </h3>
                <p className="font-size-7">
                  ¿Tienes menos de 50 empleados? En Timeview te ayudamos sin
                  coste alguno a realizar todos los trámites para conseguir tu
                  Bono Digital.
                </p>
                <div
                  className="mt-12 mt-lg-16"
                  data-aos="fade-left"
                  data-aos-duration={800}
                  data-aos-delay={500}
                  data-aos-once="true"
                >
                  <Link to="/kit-digital" className="btn btn-blue-3 rounded-5">
                    VER MÁS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
