import React from "react";

const Banner = ({ className, children, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-10">
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-delay={500}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
