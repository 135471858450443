import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/index/Hero";
import Company from "../sections/index/Company";
import Feature from "../sections/index/Feature";
import ContentOne from "../sections/index/ContentOne";
import ContentTwo from "../sections/index/ContentTwo";
import ContentThree from "../sections/index/ContentThree";
import ContentFour from "../sections/index/ContentFour";
import Review from "../sections/index/Review";
import Pricing from "../sections/index/Pricing";
import Counter from "../sections/time/Counter";
import Content5 from "../sections/index/Content5";
import Banner from "../components/Banner/Banner";
import { Link } from "gatsby";
import ContentNewPlanning from "../sections/index/ContentNewPlanning";
const Index = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          footerStyle: "style3",
        }}
      >
        <Banner className="banner p-6 mt-15 mt-lg-20">
          <Link className="font-size-6 mb-0" to="/kit-digital">
            <span className="text-blue-3 mr-3">
              <i className="fa fa-exclamation-circle" />
            </span>
            ¿Tienes menos de 50 empleados? Pulsa aquí para saber como conseguir
            Timeview <strong>gratis</strong> con el Bono Digital
          </Link>
        </Banner>
        <Hero className="bg-default-3 pt-11 pt-lg-13" />
        <Company className="pt-24 pb-9" />
        <Feature className="pt-8 pt-lg-24 pb-15 pb-lg-25 border-bottom border-default-color-3" />
        <ContentOne className="pt-27 pt-lg-35 pb-lg-34 " />
        <ContentNewPlanning className="pt-20" />
        <ContentThree className="bg-dark-cloud pt-30 pt-lg-28 pb-15 pb-lg-30" />
        <ContentTwo className="pt-27 pb-13 pb-lg-34" />
        <Review className="bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" />
        <Counter className="pt-27 pt-lg-24 pb-8 pb-lg-24" />
        <Pricing className="pt-13 pt-lg-25 pb-8 pb-lg-22 " />
        <Content5 className="pt-13 pt-lg-24 pb-16 pb-sm-12 pb-lg-34" />
        <ContentFour className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default Index;
