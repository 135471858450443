import React from "react";
import Card from "../../components/ReviewCard";

import sectionShape from "../../assets/image/inner-page/png/section-overlay-1.png";

const Review = ({ className, ...rest }) => {
  const ReviewData = [
    {
      name: "Mónica Díaz",
      title: "Responsable de RRHH en Gexco",
      comment:
        "Timeview facilita mucho el trabajo en el área de RRHH, no sólo en la planificación, organización de los turnos y equipos de trabajo sino también en la gestión de documentos.",
    },
    {
      name: "Saulo Castro",
      title: "Subdirector en Global",
      comment:
        "La capacidad de adaptación que tiene el programa a la singularidad de cada empresa. Es de las mejores herramientas que existe en el mercado para el control horario y gestión de personas.",
    },
    {
      name: "Carmen Virginia Gil",
      title: "Empleada en CiTEC",
      comment:
        "Con el teletrabajo es más complicado llevar control del tiempo que dedicas a trabajar. Con Timeview tengo un registro exacto de mi jornada diaria y además puedo gestionar mis ausencias fácilmente.",
    },
    {
      name: "Ayoze Barrera",
      title: "Responsable de desarrollo en CiTEC",
      comment:
        "Gracias a Timeview mi empresa ha podido implementar el teletrabajo sin perder el contacto con nuestros compañeros.",
    },
    {
      name: "Hector Ortega",
      title: "Desarrollador en Tagoro",
      comment:
        "Antes solía olvidarme de fichar pero gracias a Timeview ahora todo ese proceso es mucho más sencillo gracias a los avisos sobre fichajes.",
    },
    {
      name: "Mario Gil",
      title: "Desarrollador en CiTEC",
      comment:
        "Con Timeview puedo realizar mis fichajes desde cualquier dispositivo y me permite mantener una comunicación fluida con mis compañeros en todo momento, sin importar donde nos encontremos.",
    },
  ];

  return (
    <div className={`position-relative ${className}`} {...rest}>
      <div
        className="absolute-top-right"
        data-aos="fade-left"
        data-aos-delay={500}
      >
        <img src={sectionShape} alt="" />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div
              className="text-center mb-8 mb-lg-19"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h2 className="font-size-10 mb-6">
                Personas a las que hemos ayudado
              </h2>
              <p className="font-size-6 mb-0">
                Esta es la experiencia que han tenido algunos de nuestros
                clientes después de implementar timeview en su empresa.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          data-aos="zoom-in"
          data-aos-delay={800}
        >
          {ReviewData.map(({ image, name, title, comment }, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-xs-10" key={index}>
                <Card
                  image={image}
                  name={name}
                  title={title}
                  comment={comment}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Review;
