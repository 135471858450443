import React from "react";

import imgDashboard from "../../assets/image/inner-page/png/new-planning-big.png";
import imgCard from "../../assets/image/inner-page/png/new-planning-2.png";
import imgCard2 from "../../assets/image/inner-page/png/new-planning-1.png";
import imgDBG from "../../assets/image/inner-page/png/dot-bg.png";

const ContentNewPlanning = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center">
            <div className="text-center mb-13 mb-lg-22 pr-lg-10 pr-xl-0">
              <h6 className="font-size-10 text-buttercup text-uppercase mb-9 letter-spacing-normal">
                Nuevo
              </h6>
              <h2 className="font-size-11 mb-0">
                ¡Planifica con más flexibilidad que nunca!
              </h2>
            </div>
          </div>
          {/* Section Title */}
          <div className="row justify-content-center">
            {/* Image Section */}
            <div className="col-lg-9 col-md-10 ">
              <div
                className="l2-content-image-group-new-planning w-100"
                data-aos="zoom-in"
                data-aos-delay={300}
                data-aos-once="true"
              >
                {/* Dashboard Img */}
                <img
                  className="dash w-100 w-lg-auto shadow-9"
                  src={imgDashboard}
                  alt=""
                />
                {/* content-card-img-1 Img */}
                <div className="img-2">
                  <img src={imgCard} alt="" />
                </div>
                {/* End content-card-img-1 Img */}
                {/* content-card-img-2 Img */}
                <div className="img-4">
                  <img src={imgCard2} alt="" />
                </div>
                {/* End content-card-img-2 Img */}
                {/* End Img-group-content */}
                <div className="img-3">
                  <img src={imgDBG} alt="" />
                </div>
                {/* End Dashboard Img */}
              </div>
            </div>
          </div>
          {/* Section Text */}
          <div className="mt-18 mb-30 row align-items-xl-center justify-content-between">
            <div
              className="col-xl-7 col-lg-6 col-md-10"
              data-aos="fade-right"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <h3 className="font-size-10 mb-0 letter-spacing-n83">
                Turnos predefinidos o personalizados, ¡tu eliges!
              </h3>
            </div>
            <div
              className="col-xl-5 col-lg-6 col-md-8"
              data-aos="fade-left"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <p className="font-size-7 mb-0 pr-xl-2 pr-lg-5 pr-md-10 pr-sm-20 mt-7 mt-lg-0">
                Con la nueva función de <i>drag and drop</i> podrás ajustar la
                realidad de tu equipo de forma muy práctica.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentNewPlanning;
