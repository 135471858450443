import React from "react";
import { Link } from "gatsby";

import DashBoardImage from "../../assets/image/inner-page/png/desktop-dashboard-x2.png";
import YellowShape from "../../assets/image/inner-page/png/yellow-shape.png";
import GreenShape from "../../assets/image/inner-page/png/blue-shape.png";

const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-7 col-md-9">
            <div
              className="text-center mb-11 mb-lg-15"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h1 className="font-size-11 mb-7">
                Transforma la <span className="text-blue-3">experiencia</span>{" "}
                de la gestión de personas
              </h1>
              <p className="font-size-7 px-lg-5 mb-0">
                Gestiona y planifica de manera sencilla tus recursos humanos en
                tiempo real
              </p>
              <div className="btn-group d-inline-block mt-12">
                <Link className="btn btn-blue-3 btn-2 rounded-5" to="/contact">
                  ¿Hablamos?
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Hero Image Group */}
        <div className="row justify-content-center">
          <div className="col-md-10 col-xs-11">
            <div className="l3-hero-image-group w-100">
              {/* Image One */}
              <div
                className="img-1 shadow-13 custom-hero"
                data-aos="fade-up"
                data-aos-delay={500}
                data-aos-once="true"
              >
                <img src={DashBoardImage} className="main-img" alt="" />
                {/* Image Two */}
                <div className="img-2">
                  <img
                    src={YellowShape}
                    alt=""
                    data-aos="fade-right"
                    data-aos-delay={700}
                    data-aos-once="true"
                  />
                </div>
                {/* Image Three */}
                <div className="img-3">
                  <img
                    src={GreenShape}
                    alt=""
                    data-aos="fade-left"
                    data-aos-delay={900}
                    data-aos-once="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Hero Image Group */}
      </div>
    </div>
  );
};

export default Hero;
