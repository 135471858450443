import React, { useRef } from "react";
import Card from "../../components/FeatureCard";
import Slider from "react-slick";

const Feature = ({ className, ...rest }) => {
  const elSlider = useRef();
  const sliderSettings = {
    autoplay: true,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 3,
    arrows: false,
    touchMove: true,
    infinite: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          centerPadding: "10px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          centerPadding: "80px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          centerPadding: "10%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          centerPadding: "30px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  const featureCards = [
    {
      icon: "fa-clock",
      title: "Control de horario",
      text:
        "Cumple la normativa vigente dándoles a tus empleados una manera fácil, rápida e intuitiva para fichar.",
    },
    {
      icon: "fa-fingerprint",
      title: "Fichar",
      text:
        "Elige la manera de fichar que mejor se adapte a tu empresa, ya sea desde nuestra app móvil o un sistema biométrico.",
    },
    {
      icon: "fa-calendar",
      title: "Gestión de turnos",
      text:
        "Asigna y edita de manera sencilla el turno o tareas de tus empleados.",
    },
    {
      icon: "fa-magic",
      title: "Planificador automático",
      text:
        "Usa nuestro sistema automático de turnos, que aplica las reglas de trabajo que contemple el convenio de la empresa.",
    },
    {
      icon: "fa-home",
      title: "Teletrabajo",
      text:
        "¡El teletrabajo ha llegado! Gestiona a tu equipo de manera remota.",
    },
    {
      icon: "fa-coins",
      title: "Ahorro de costes",
      text:
        "Al reducir o eliminar el consumo de papel disminuyen los costes vinculados a su impresión además del coste de su almacenaje.",
    },
    {
      icon: "fa-signature",
      title: "Gestión documental",
      text:
        "Distribuye la documentación que requieran tus empleados, con la posibilidad de incluir la firma electrónica.",
    },
    {
      icon: "fa-shield-alt",
      title: "Mayor seguridad",
      text: "Genera y descarga copias de seguridad de tus datos.",
    },
    {
      icon: "fa-plane",
      title: "Gestión de viajes",
      text:
        "Planifica los viajes de la empresa para tener un control de toda la información y todos los gastos.",
    },
    {
      icon: "fa-comment",
      title: "Comunicación interna",
      text:
        "Comunicación entre empleados usando chats privados o chats públicos generados por la empresa.",
    },
    {
      icon: "fa-user",
      title: "Mejora la experiencia del empleado",
      text:
        "De manera clara, sencilla e intuitiva el empleado sabrá su situación en la empresa.",
    },
    {
      icon: "fa-tree",
      title: "Ecofriendly",
      text:
        "Contribuimos a reducir el impacto medioambiental evitando el consumo de papel al ser 100% digitales.",
    },
    {
      icon: "fa-project-diagram",
      title: "Proyectos",
      text:
        "Añade los proyectos y tareas que su empresa necesite gestionar para un mayor control.",
    },
    {
      icon: "fa-users",
      title: "Equipos",
      text:
        "Configura tus equipos y conoce la situación actualizada accediendo al resumen de equipos.",
    },
    {
      icon: "fa-umbrella-beach",
      title: "Vacaciones, ausencias y horas extras",
      text:
        "Tu equipo puede realizar estas solicitudes que quedarán pendiente por el responsable del equipo.",
    },
    {
      icon: "fa-retweet",
      title: "Permutas",
      text:
        "Intercambio de turnos o tareas de forma autónoma o revisables por el responsable.",
    },
    {
      icon: "fa-tshirt",
      title: "Equipamiento",
      text:
        "Controla la uniformidad y equipamiento que necesite sus empleados para desempeñar su función.",
    },
    {
      icon: "fa-bullhorn",
      title: "Canal de denuncias",
      text: "Las normativas vigentes exigen contar con un canal de denuncias.",
    },
    {
      icon: "fa-graduation-cap",
      title: "Formación",
      text: "Mantén formados a tu personal con nuestra gestión de cursos.",
    },
  ];

  return (
    <div className={className} {...rest}>
      {/* feature section */}
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div
              className="text-center mb-8 mb-lg-14"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h2 className="font-size-10 mb-6 letter-spacing-n83">
                ¿Dónde impactan nuestras soluciones?
              </h2>
              <p className="font-size-6 mb-0">
                Afrontamos todos los problemas a los que las empresas se suelen
                enfrentar, simplificando el proceso para que no pierdas el
                tiempo.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Title */}
      </div>
      {/* feature slider */}
      <div className="row">
        <div className="col-12" data-aos="zoom-in" data-aos-delay={800}>
          {/* <div className="">
            </div> */}
          <Slider
            ref={elSlider}
            {...sliderSettings}
            className="l3-feature-slider d-flex"
          >
            {featureCards.map(({ icon, title, text }, index) => {
              return <Card icon={icon} title={title} text={text} key={index} />;
            })}
          </Slider>
        </div>
      </div>
      {/* End feature slider */}
      {/* <div className="row justify-content-center">
        <div className="col-12">
          <div
            className="mt-10 mt-lg-12 text-center"
            data-aos="zoom-in"
            data-aos-delay={1000}
          >
            <a
              className="video-btn btn btn-blue-3 btn-2 rounded-5"
              href="https://www.youtube.com/watch?v=LWZ7iytIA6k"
            >
              <i className="fa fa-play-circle mr-5" /> Ver Timeview en acción
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Feature;
